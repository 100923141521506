html,
body {
  width: $mainWidth;
  height: $mainHeight;
  padding: 0;
  margin: 0 auto;
  background-color: $backgroundColor;
  font-family: MetricHPERegular;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: $primaryTextColor;
  &:lang(ru-ru) {
    font-family: Arial, sans-serif;
  }
}

h1 {
  font-family: MetricHPEBold;
  font-size: 3rem;
  line-height: 2.5rem;
  font-weight: normal;
  font-style: normal;
  padding-top: 0.75rem;
  margin: 0;

  &:lang(ru-ru) {
    font-size: 2.75rem;
    line-height: 2.25rem;
    padding-top: 0.75rem;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
}
h2 {
  font-family: MetricHPERegular;
  font-size: 1.5rem;
  font-weight: normal;
  font-style: normal;
  padding: 0;
  margin: 0;
  &:lang(ru-ru) {
    font-family: Arial, sans-serif;
  }
}
p {
  font-family: MetricHPERegular;
  font-size: 1.5rem;
  font-weight: normal;
  font-style: normal;
  &:lang(ru-ru) {
    font-family: Arial, sans-serif;
  }
}

strong {
  font-family: MetricHPEBold;
  &:lang(ru-ru) {
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
}

// remove blue outline in chrome
*:focus {
  outline: 0 !important;
}
