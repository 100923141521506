// color
$backgroundColor: #fff;
$borderColor: #707070;
$cardBgColor: #ffffff;
$primaryBrandColor: #01a982;
$debugColor: #ff000077;
$hpeMediumBlue: #32dac8;
$hpeDarkBlue: #0e5265;
$hpeLightBlue: #7ff9e2;
$hpeMediumGray: #d8d8d8;
$lightGreyColor: #eeeeee;
$hpeGrayColor1: #0e5265;
$hpeGrayColor2: #7698a2;
$hpeGrayColor3: #b9cad1;
$hpeGrayColor4: #d8d8d8;

$privateColor1: #6e39e1;
$privateColor2: #b24ef6;
$equalColor1: #c6c6c2;
$publicColor1: #6dd7c9;
$publicColor2: #9ef6e2;

$altColor1: #a6a6a6;
$altColor2: #c6c5c2;

$primaryTextColor: #333333;
$primaryLineColor: #000000;
$secondaryLineColor: #a6a6a6;
$inversePrimaryTextColor: #ffffff;

// size
$mainWidth: 1400px; //90vw;
$mainHeight: 100vh;
