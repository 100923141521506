.button {
  padding: 0;
  margin: 2rem 0;
  em {
    font-family: 'MetricHPERegular';
    text-transform: uppercase;
    display: block;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    color: $primaryTextColor;
    &:lang(ru-ru) {
      font-size: 1.25rem;
      font-family: Arial, sans-serif;
    }
  }
  button {
    display: inline-block;
    font-family: 'MetricHPEBold';
    font-style: normal;
    font-weight: normal;
    border: 0;
    font-size: 2.1rem;
    text-transform: none;
    padding: 0 3.5rem 0 0;
    margin: 0;
    color: $primaryBrandColor;
    background-color: transparent;
    text-align: left;
    background-image: url(../../assets/images/arrow.svg);
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 40px 40px;
    transition: 0.1s all ease;
    &:lang(ru-ru) {
      font-weight: bold;
      font-size: 1.5rem;
      font-family: Arial, sans-serif;
    }
    span {
      white-space: nowrap;
    }
    &:hover {
      cursor: pointer;
      background-position: 100% 50%;
      transition: 0.1s all ease;
    }
  }
}
