.visualization {
  // border: 1px solid $debugColor;
  .debug {
    display: inline-block;
    color: $debugColor;
    text-align: right;
    position: absolute;
    top: 0;
    left: 0;
    ul {
      padding: 0;
      margin: 0;
      display: inline-block;
      list-style-type: none;
      li {
        margin: 0 15px;
        padding: 0;
        border-bottom: 1px solid $debugColor;
      }
    }
  }
}
