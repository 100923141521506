.dropdown {
  display: inline-block;
  padding-right: 2rem;
  strong {
    display: block;
    text-transform: uppercase;
    font-family: MetricHPERegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    padding: 1.5rem 0 0.5rem 0;
    margin: 0;
    &:lang(ru-ru) {
      font-size: 0.9rem;
      font-weight: bold;
      font-family: Arial, sans-serif;
    }
  }
  select {
    font-family: MetricHPERegular;
    font-style: normal;
    font-weight: normal;
    height: 50px;
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    padding: 0.5rem;
    min-width: 200px;
    width: 200px;
    background-color: $lightGreyColor;
    &:lang(ru-ru) {
      font-size: 1rem;
      font-family: Arial, sans-serif;
    }
  }
}
