.section-detail-nav {
  display: flex;
  font-size: 2rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid $primaryTextColor;
  border-radius: 10px;
  width: 200px;

  strong {
    font-family: MetricHPERegular;
    font-weight: normal;
    width: 1rem;
    text-align: center;
    padding: 1rem;
    margin: 0;
    height: 40px;

    &.normal:hover {
      cursor: default;
      background-color: transparent;
    }
    &.results {
      border-left: 1px solid $primaryTextColor;
      border-right: 1px solid $primaryTextColor;
      width: 3em;
      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
    &.next:hover {
      cursor: pointer;
      border-radius: 0 10px 10px 0;
      background-color: $hpeMediumGray;
      &.normal:hover {
        cursor: default;
        background-color: transparent;
      }
    }
    &.previous:hover {
      cursor: pointer;
      border-radius: 10px 0 0 10px;
      background-color: $hpeMediumGray;
      &.normal:hover {
        cursor: default;
        background-color: transparent;
      }
    }
  }
}
