.section-nav {
  // border: 1px solid $debugColor;
  font-size: 1rem;
  &:lang(ru-ru) {
    font-family: Arial, sans-serif;
  }

  ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    li {
      display: inline-block;
      padding: 0.5rem;
      margin: 0.5rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .current {
    font-family: MetricHPEBold;
    font-style: normal;
    font-weight: normal;
    &:lang(ru-ru) {
      font-weight: bold;
      font-family: Arial, sans-serif;
    }
  }
}
