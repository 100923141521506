@font-face {
  font-family: MetricHPERegular;
  src: url('../assets/fonts/MetricHPE-Web-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MetricHPEBold;
  src: url('../assets/fonts/MetricHPE-Web-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
