@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.graph {
  // font-family: 'MetricHPERegular' !important;
  // border: 1px solid $debugColor;
  position: absolute;
  display: block;
  transition-duration: 0.5s;
  transition-delay: 0s;
  animation: fadeIn ease 1s; // Remove this for print
}
