.filter-group {
  // border: 1px dotted $debugColor;
  display: flex;
  flex-direction: column;
  width: 210px;
  padding: 0 1rem 1rem 1rem;

  h3 {
    text-transform: uppercase;
    font-family: MetricHPERegular;
    font-style: normal;
    font-size: 1rem;
    border-bottom: 1px solid $primaryTextColor;
    padding: 1rem 0 0.5rem 0;
    margin: 0;
    .print {
      display: none;
    }
    &:lang(ru-ru) {
      font-family: Arial, sans-serif;
    }
  }

  strong {
    font-size: 1rem;
    padding: 1rem 0 0.5rem 0;
    margin: 0;
  }
  select {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    height: 40px;
    min-width: 210px;
  }
}
